module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"linkImagesToOriginal":false,"backgroundColor":"transparent","withWebp":true,"disableBgImage":true,"quality":100,"wrapperStyle":"float: right; width: 100%; margin-left: 0.5em; margin-bottom: 0.5em;","showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"maintainCase":false,"removeAccents":true,"offsetY":0,"className":"anchor"},
    }]
