// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-card-editor-example-js": () => import("./../../../src/pages/card-editor-example.js" /* webpackChunkName: "component---src-pages-card-editor-example-js" */),
  "component---src-pages-card-editor-js": () => import("./../../../src/pages/card-editor.js" /* webpackChunkName: "component---src-pages-card-editor-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-cards-js": () => import("./../../../src/pages/my-cards.js" /* webpackChunkName: "component---src-pages-my-cards-js" */),
  "component---src-pages-searchresults-js": () => import("./../../../src/pages/searchresults.js" /* webpackChunkName: "component---src-pages-searchresults-js" */),
  "component---src-templates-card-template-js": () => import("./../../../src/templates/card-template.js" /* webpackChunkName: "component---src-templates-card-template-js" */),
  "component---src-templates-collection-template-js": () => import("./../../../src/templates/collection-template.js" /* webpackChunkName: "component---src-templates-collection-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-wiki-template-js": () => import("./../../../src/templates/wiki-template.js" /* webpackChunkName: "component---src-templates-wiki-template-js" */)
}

